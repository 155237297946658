import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
:root {
    --color-red: #FF0000;
    --color-amber: #FBB70A;
}
* {
    font-family: "Noto Sans", sans-serif;  
}
html {
    --color-primary: #2B7BD9;

    --color-books-background: #EEF6FF;
    --color-books-primary: #2B7BD9;
    --color-pricing-background: #BFFFC2;
    --color-pricing-primary: #19AF00;
    --color-logout-background: #FFDEDE;
    --color-logout-primary: #FF0000;

    --color-gray: #EBEBEB;

    --color-paper: var(--color-white);
    --color-paper-rough: #e8e8e8;

    --color-warning: var(--color-amber);
    --color-error: var(--color-red);
    --color-primary: #2B7BD9;

    --color-text-default: black;
    --color-text-secondary: #707070;

    --padding-button-default: 0.5rem 1rem;
    --margin-button-default: 0.5rem;
    --margin-button-icon-default: 0 0.5rem 0 0;

    --margin-input-default: 0.5rem;
    --padding-input-default: 0.5rem 1rem;

    --padding-notification-default: 0.5rem 1rem;
    --margin-notification-default: 0.5rem;
    --margin-notification-icon-default: 0 0.5rem 0 0;
    --transition-time-notification: 0.7s;

    --box-shadow-1: 2px 2px 2px rgba(0,0,0, 0.25);
    --box-shadow-2: 5px 5px 5px rgba(0,0,0, 0.25);
    --box-shadow-3: 8px 8px 8px rgba(0,0,0, 0.25);

    --z-index-notifications: 10000;
    --z-index-modal: 1000;
    --z-index-header: 50;
    --z-index-date-picker: 200;

    --font-size-1: 34px;
    --font-size-2: 28px;
    --font-size-3: 22px;
    --font-size-4: 18px;
    --font-size-5: 16px;
    --font-size-6: 14px;
    --font-size-7: 12px;
    
    user-select: none;
}

`;

export default GlobalStyles;
