import firebase from "firebase";
import "firebase/firestore";
import "firebase/auth";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

console.log("initialized");


// Auth

export default firebase;

export const auth = firebase.auth();

export const signUp = async (email, password) => {
  return auth
    .createUserWithEmailAndPassword(email, password)
    .catch((err) => console.log(err));
};

export const signIn = async (email, password) => {
  return auth
    .signInWithEmailAndPassword(email, password)
    .catch((err) => console.log(err));
};

export const signOut = async () => {
  return auth.signOut().catch((err) => console.error(err));
};

// Firestore

export const firestore = firebase.firestore();

export const getBook = async (slug) => {
  console.log(slug);
  return firestore
    .collection("books")
    .where("slug", "==", slug)
    .limit(1)
    .get()
    .then((snapshot) => {
      let data = [];
      snapshot.forEach((doc) => {
        data.push({ ...doc.data(), id: doc.id });
      });
      return data[0];
    })
    .catch((err) => {
      console.log(err);
    });
};

export const addBook = async (book) => {
  return firestore
    .collection("books")
    .add(book)
    .then(() => {
      console.log("yay");
    })
    .catch((err) => {
      console.error(err);
    });
};

export const updateBook = async (id, book) => {
  return firestore
    .doc(`books/${id}`)
    .update(book)
    .then(() => console.log("yay"))
    .catch((err) => {
      console.error(err);
    });
};

export const deleteBook = async (id) => {
  return firestore
    .doc(`books/${id}`)
    .delete()
    .then(() => console.log("yay"))
    .catch((err) => {
      console.error(err);
    });
};

export const getBooks = async () => {
  return firestore
    .collection("books")
    .get()
    .then((snapshot) => {
      let data = [];
      snapshot.forEach((doc) => {
        data.push({ ...doc.data(), id: doc.id });
      });
      return data;
    });
};

export const getPageContent = async (page) => {
  return firestore
    .collection("pageTitles")
    .where("page", "==", page)
    .limit(1)
    .get()
    .then((snapshot) => {
      let data = [];
      snapshot.forEach((doc) => {
        data.push({ ...doc.data(), id: doc.id });
      });
      return data[0];
    })
    .catch((err) => {
      console.error(err);
    });
};

export const addPageContent = async (item) => {
  return firestore
    .collection("pageTitles")
    .add(item)
    .then(() => {
      console.log("yay");
    })
    .catch((err) => {
      console.error(err);
    });
};

export const updatePageContent = async (id, item, N) => {

  return firestore
    .doc(`pageTitles/${id}`)
    .update(item)
    .then(() => console.log("yay"))
    .catch((err) => {
      console.error(err);
      N.error(err, err.message);
    });
};
