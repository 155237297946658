import styled from "styled-components";

const Input = styled.input`
  background-color: #eee;
  padding: 0.5rem;
  border-radius: 10px;
  border: none;
`;

export default Input;
