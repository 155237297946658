import styled from "styled-components";

const TextArea = styled.textarea`
  background-color: #eee;
  padding: 0.5rem;
  border-radius: 10px;
  border: none;
  resize: vertical;
  min-height: 100px;
`;

export default TextArea;
