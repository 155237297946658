import styled from "styled-components";

import Loading from "components/Loading";

const Button = ({ children, loading, ...rest }) => {
  return (
    <StyledButton {...rest}>
      {loading ? <Loading /> : <>{children}</>}
    </StyledButton>
  );
};

const StyledButton = styled.button`
  ${(p) => p.primary && "background-color: #2B7BD9;"}
  ${(p) => p.secondary && "background-color: #EEF6FF;"}
    ${(p) =>
    p.disabled && "background-color: gray;"}

    &:hover {
    filter: brightness(0.9);
  }

  cursor: pointer;
  padding: 0.5rem 2rem;
  border-radius: 2rem;

  border: none;
  margin: 0.5rem;

  > span {
    font-weight: 700;
    color: ${(p) => (p.primary ? "white" : "black")};
  }
`;

export default Button;
