import { useNotifications } from "context/Notifications";
import { useModal } from "context/Modal";
import { useState, useEffect } from "react";
import book_icon from "./book_icon.png";
import logo from "./growth-notes-logo3x.png";

import styled from "styled-components";

import { auth, firestore, signIn, signOut } from "./firebase/firebase.utils";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPowerOff,
  faList,
  faPlus,
  faMoneyCheck,
} from "@fortawesome/free-solid-svg-icons";

import Select from "components/Select";
import Menu from "components/Menu";
import BookList from "components/BookList";
import PageInsight from "components/PageInsight";
import PageLibrary from "components/PageLibrary";
import PageMember from "components/PageMember";
import PageCycle from "components/PageCycle";
import Input from "components/Input";
import Button from "components/Button";
import PageHowItWork from "components/PageHowItWork";
import PageHomeContent from "components/PageHomeContent";

const App = () => {
  const [filter, setFilter] = useState(null);
  const [key, setKey] = useState(1);
  const [view, setView] = useState('books');

  const [loggedIn, setLoggedIn] = useState(false);

  const N = useNotifications();

  const page = () => {
    switch (view) {
      case "books":
        return (
          <BookList filter={filter} setFilter={setFilter} />
        )
      case "insights":
        return (
          <PageInsight page={'insights'} pageTitle={"Edit Insight Help Content"} showFirstField={false} cTitle={'Help Content'} />
        )
      case "library":
        return (
          <PageLibrary page={'library'} pageTitle={"Edit Library Page"} showFirstField={true} cTitle={'Sub Text'} />
        )
      case "member":
        return (
          <PageMember page={'member'} pageTitle={"Edit Member Guidelines text"} showFirstField={true} cTitle={'Content'} />
        )
      case "cycle":
        return (
          <PageCycle page={'cycle'} pageTitle={"Edit Growth Cycle Help box"} showFirstField={false} cTitle={'Content'} />
        )
      case "howitwork":
        return (
          <PageHowItWork page={'howitwork'} pageTitle={"Edit How it work box"} showFirstField={false} cTitle={'Content'} />
        )
      case "homecontent":
        return (
          <PageHomeContent page={'homecontent'} pageTitle={"Edit Home"} showFirstField={false} cTitle={'Content'} />
        )
      default:
        return (
          <BookList filter={filter} setFilter={setFilter} />
        )
    }
  }
  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      console.log("auth changed");
      if (user) {
        if (user.email === "samy@growthnot.es") {
          console.log(user);
          setLoggedIn(true);
        } else {
          N.warn("You do not have access to this service");
          signOut();
          setLoggedIn(false);
        }
      } else {
        setLoggedIn(false);
      }
    });
  }, []);

  return (
    <>
      {loggedIn ? (
        <Container>
          <Menu setKey={setKey} setView={setView} />
          <Body key={key}>
            {view === "books" && (
              <BodyHeader>
                <TitleElement
                  style={{
                    backgroundColor: "var(--color-books-background)",
                  }}
                >
                  <div>
                    <img src={book_icon} alt="book icon" />
                  </div>
                  <span>Books</span>
                </TitleElement>
                <SearchElement filter={filter} setFilter={setFilter} />
              </BodyHeader>
            )}
            {
              page()
            }
          </Body>
        </Container>
      ) : (
        <LoginCard />
      )}
    </>
  );
};

export default App;

const Container = styled.div`
  padding: 0 5rem;
  display: flex;
`;

const Body = styled.div`
  width: 1000px;
  padding: 2rem;
  display: flex;
  margin-left: 20vw;
  flex-direction: column;
`;

const BodyHeader = styled.div`
  display: flex;
  margin-bottom: 1rem;
`;

const TitleElement = styled.div`
  border-radius: 2rem;
  box-shadow: var(--box-shadow-2);
  display: flex;
  align-items: center;
  background-color: var(--color-books-background);
  padding: 1rem;
  margin-right: 1rem;
  *:first-child {
    margin-right: 1rem;
  }
  > span {
    font-weight: 700;
  }
  > div {
    width: 16px;
    display: grid;
    > img {
      width: 100%;
    }
  }
`;

const SearchElement = ({ filter, setFilter }) => {
  return (
    <SearchElementContainer>
      <span>Search Books </span>
      <Input onChange={(e) => setFilter(e.target.value)} value={filter} />
    </SearchElementContainer>
  );
};

const SearchElementContainer = styled.div`
  box-shadow: var(--box-shadow-2);
  border-radius: 2rem;
  display: flex;
  align-items: center;
  background-color: white;
  padding: 1rem;
  > span {
    font-weight: 700;
    margin-right: 1rem;
  }
`;

const LoginCard = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [waiting, setWaiting] = useState(null);

  const handleSubmit = async () => {
    setWaiting("login");
    await signIn(username, password);
    setWaiting(null);
  };

  return (
    <OuterContainer>
      <LoginCardContainer>
        {/* <img src={logo} alt="logo" /> */}
        <FormContainer>
          <span>Username</span>
          <Input
            onChange={(e) => setUsername(e.target.value)}
            value={username}
          />
        </FormContainer>
        <FormContainer>
          <span>Password</span>
          <Input
            type="password"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
          />
        </FormContainer>
        <Button primary loading={waiting === "login"} onClick={handleSubmit}>
          <span>Login</span>
        </Button>
      </LoginCardContainer>
    </OuterContainer>
  );
};

const OuterContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: grid;
  place-items: center;
`;

const LoginCardContainer = styled.div`
  width: 40vw;
  border-radius: 2rem;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 2rem;
  > img {
    width: 250px;
  }
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 1rem;
`;
