import styled from "styled-components";
import { useState, useEffect, useMemo } from "react";

import Input from "components/Input";
import TextArea from "components/TextArea";
import Button from "components/Button";
import Select from "components/Select";

import { useNotifications } from "context/Notifications";
import { useModal } from "context/Modal";

import Dropzone from "components/Dropzone";

import useUpload from "tools/useUpload";

import { addBook, updateBook, deleteBook } from "../firebase/firebase.utils";

const categoryOptions = [
  {
    label: "Air",
    value: "Air",
  },
  {
    label: "Earth",
    value: "Earth",
  },
  {
    label: "Water",
    value: "Water",
  },
  {
    label: "Fire",
    value: "Fire",
  },
];

const BookModal = ({ book, refreshBookList }) => {
  
  const [title, setTitle] = useState(book ? book.title : "");
  const [author, setAuthor] = useState(book ? book.author : "");
  const [link, setLink] = useState(book ? book.link : "");
  const [videoLink, setVideoLink] = useState(book ? book.videoLink : "");
  const [summary, setSummary] = useState(book ? book.summary : "");
  const [highlights, setHighlights] = useState(book ? book.highlights : "");
  const [category, setCategory] = useState(
    book ? categoryOptions.find((op) => op.value === book.category) : ""
  );
  const [slug, setSlug] = useState(book ? book.slug : null);
  const [free, setFree] = useState(book ? book.free : false);
  const [isNewBook, setIsNewBook] = useState(book ? book.new : false);
  const [isHide, setIsHide] = useState(book ? book.hide : false);

  const [waiting, setWaiting] = useState(null);

  const [image] = useState(book ? book.image : null);
  const [id] = useState(book ? book.id : null);

  const N = useNotifications();

  const { closeModal } = useModal();

  const [newFile, setNewFile] = useState(null);

  const { uploadFile } = useUpload();

  const handleFileUpload = async (files) => {
    setNewFile(files[0]);
  };

  const pseudo = useMemo(() => Math.floor(Math.random() * 10000000), []);

  useEffect(() => {
    if (title && !id) {
      let titleToSlug = title.split(" ").join("-") + "-" + pseudo;
      setSlug(titleToSlug);
      console.log(titleToSlug);
    }
  }, [title]);

  const handleDelete = async () => {
    try {
      setWaiting("delete");
      await deleteBook(id);
      await refreshBookList();
      setWaiting(null);
      closeModal();
    } catch (err) {
      N.error(err);
    }
  };

  const handleSubmit = async () => {
    // validation
    if (!title || !author || !link || !summary || !slug || !category) {
      N.warn("Missing required fields");
      return;
    }
    setWaiting("submit");

    try {
      // file upload
      let imageUrl;

      if (newFile) {
        imageUrl = await uploadFile("covers/" + newFile.name, newFile);
      } else if (!id) {
        imageUrl =
          "https://firebasestorage.googleapis.com/v0/b/growthnotes-976fa.appspot.com/o/no_cover.png?alt=media&token=194bbacd-edbf-4a34-99a6-db07cf6c66e9";
      } else {
        imageUrl = null;
      }

      console.log("done");
      // firestore save (using url from upload)
      if (!id) {
        let newBook = {
          title,
          author,
          link,
          summary,
          highlights: highlights ? highlights : null,
          category: category.value,
          slug,
          free,
          new: isNewBook ? true : false,
          hide: isHide ? true : false,
          image: imageUrl,
          videoLink: videoLink ? videoLink : null,
          createdAt: new Date(),
        };
        await addBook(newBook);
        await refreshBookList();
        setWaiting(null);
        closeModal();
      } else {
        let newBook = {
          title,
          author,
          link,
          summary,
          highlights: highlights ? highlights : null,
          category: category.value,
          videoLink: videoLink ? videoLink : null,
          slug,
          free,
          new: isNewBook ? true : false,
          hide: isHide ? true : false,
          image: imageUrl ? imageUrl : image,
        };
        await updateBook(id, newBook);
        await refreshBookList();
        setWaiting(null);
        closeModal();
      }
    } catch (err) {
      setWaiting(null);
      N.error(err);
    }
  };

  const handleInputChange = (e) => {
    switch (e.target.name) {
      case "title":
        setTitle(e.target.value);
        break;
      case "author":
        setAuthor(e.target.value);
        break;
      case "link":
        setLink(e.target.value);
        break;
      case "summary":
        setSummary(e.target.value);
        break;
      case "highlights":
        setHighlights(e.target.value);
        break;
      case "free":
        setFree((f) => !f);
        break;
      case "videoLink":
        setVideoLink(e.target.value);
        break;
      case "newBook":
        setIsNewBook((n) => !n);
        break;
      case "isHide":
        setIsHide((n) => !n);
        break;
      default:
        console.error("Field name not found");
    }
  };

  const handleCategoryChange = (e) => {
    setCategory(e);
  };

  return (
    <Container>
      <BookHeader>
        <TitleContainer>
          <TitleSuperscript>Title</TitleSuperscript>
          <Input
            onChange={handleInputChange}
            name={"title"}
            value={title}
            style={{ flexGrow: 1, fontSize: "var(--font-size-3)" }}
          />
        </TitleContainer>
      </BookHeader>
      <BookBody>
        <BookCoverHolder>
          <Dropzone
            handleFileUpload={handleFileUpload}
            image={image}
            newImageName={newFile ? newFile.name : null}
          />
        </BookCoverHolder>
        <BookDetails>
          <DetailsRow>
            <BookDetailForm
              title={"Author"}
              value={author}
              valueName={"author"}
              handleInputChange={handleInputChange}
            />
            <BookDetailForm
              title={"Link"}
              value={link}
              valueName={"link"}
              handleInputChange={handleInputChange}
            />
          </DetailsRow>
          <DetailsRow>
            <BookDetailForm
              title={"Summary"}
              value={summary}
              valueName={"summary"}
              type="textarea"
              handleInputChange={handleInputChange}
            />
          </DetailsRow>
          <DetailsRow>
            <BookDetailForm
              title={"Highlights"}
              value={highlights}
              valueName={"highlights"}
              type="textarea"
              handleInputChange={handleInputChange}
            />
          </DetailsRow>
          <DetailsRow>
            <BookDetailForm
              title={"Video Link"}
              value={videoLink}
              valueName={"videoLink"}
              handleInputChange={handleInputChange}
            />
          </DetailsRow>
          <DetailsRow>
            <BookDetailForm
              title={"Category"}
              value={category}
              valueName={"category"}
              type="category"
              handleInputChange={handleCategoryChange}
            />
            <BookDetailForm
              title={"Free?"}
              value={free}
              valueName={"free"}
              handleInputChange={handleInputChange}
              type={"checkbox"}
            />
            <BookDetailForm
              title={"New?"}
              value={isNewBook}
              valueName={"newBook"}
              handleInputChange={handleInputChange}
              type={"checkbox"}
            />
            <BookDetailForm
              title={"Soon?"}
              value={isHide}
              valueName={"isHide"}
              handleInputChange={handleInputChange}
              type={"checkbox"}
            />
          </DetailsRow>
        </BookDetails>
      </BookBody>
      <BookButtonRow>
        <Button secondary onClick={closeModal}>
          <span>Cancel</span>
        </Button>
        {id && (
          <Button
            secondary
            loading={waiting === "delete"}
            onClick={handleDelete}
            style={{ backgroundColor: "var(--color-logout-background)" }}
          >
            <span style={{ color: "var(--color-logout-primary)" }}>Delete</span>
          </Button>
        )}
        <Button primary loading={waiting === "submit"} onClick={handleSubmit}>
          <span>Save</span>
        </Button>
      </BookButtonRow>
    </Container>
  );
};

export default BookModal;

const BookButtonRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
`;

const Container = styled.div`
  width: 60vw;
  position: relative;
  top: -30px;
  padding: 2rem;
  border-radius: 2rem;
  background-color: white;
  margin-bottom: 0;
  padding-bottom: 0;
`;

const BookHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const BookBody = styled.div`
  display: grid;
  grid-template-columns: 33% 66%;
  margin-top: 2rem;
`;

const BookCoverHolder = styled.div`
  width: 100%;
  > img {
    width: 72%;
  }
`;

const BookDetails = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 1rem;
`;

const DetailsRow = styled.div`
  width: 100%;
  display: flex;
`;

const BookDetailForm = ({
  title,
  value,
  valueName,
  handleInputChange,
  type,
}) => {
  return (
    <Detail>
      <DetailTitle>{title}</DetailTitle>
      {type === "textarea" && (
        <TextArea onChange={handleInputChange} name={valueName} value={value} />
      )}
      {type === "category" && (
        <div style={{ maxWidth: "100%" }}>
          <Select
            onChange={handleInputChange}
            options={categoryOptions}
            value={value}
          />
        </div>
      )}
      {type === "checkbox" && (
        <div style={{ maxWidth: "100%" }}>
          <input
            type={"checkbox"}
            onChange={handleInputChange}
            checked={value}
            name={valueName}
          />
        </div>
      )}
      {!type && (
        <Input onChange={handleInputChange} name={valueName} value={value} />
      )}
    </Detail>
  );
};

const Detail = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  margin: 0.5rem;
  margin-bottom: 0.5rem;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const DetailTitle = styled.span`
  font-size: var(--font-size-7);
  margin-bottom: 0.25rem;
  font-weight: 700;
`;

const TitleSuperscript = styled.span`
  font-size: var(--font-size-5);
`;
