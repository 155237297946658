import { useEffect, useState } from "react";
import styled from "styled-components";

import { getBooks } from "firebase/firebase.utils.js";

import Button from "components/Button";
import Loading from "components/Loading";
import BookModal from "components/BookModal";

import { useModal } from "context/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp, faAngleDown } from "@fortawesome/free-solid-svg-icons";
import ElementContainer from "./atomic/ElementContainer";
const categoryOptions = [
  {
    label: "Peace",
    value: "Air",
  },
  {
    label: "Focus",
    value: "Earth",
  },
  {
    label: "Connection",
    value: "Water",
  },
  {
    label: "Wealth",
    value: "Fire",
  },
];

const BookList = ({ filter, setFilter }) => {
  const [loading, setLoading] = useState(true);
  const [allBooks, setAllBooks] = useState(null);
  const [books, setBooks] = useState(null);

  useEffect(() => {
    (async () => {
      const response = await getBooks();
      console.log(response);
      setAllBooks(response);
      setBooks(response);
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    if (filter) {
      setBooks(
        allBooks.filter(
          (b) =>
            b.title.toLowerCase().includes(filter.toLowerCase()) ||
            b.author.toLowerCase().includes(filter.toLowerCase())
        )
      );
    } else {
      setBooks(allBooks);
    }
  }, [filter]);

  const refreshBookList = async () => {
    setLoading(true);
    const response = await getBooks();
    setAllBooks(response);
    setBooks(response);
    setFilter(null);
    setLoading(false);
  };

  return (
    <Container>
      {loading && <Loading />}
      {books &&
        books.map((book) => (
          <Book book={book} refreshBookList={refreshBookList} />
        ))}
    </Container>
  );
};

export default BookList;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Book = ({ book, refreshBookList }) => {
  const { openModal } = useModal();
  const [open, setOpen] = useState(false);
  const bookCategory = (category) => {
    var item = categoryOptions.find( ele => {
      return ele.value === category;
    });
    return item ? item.label : '';
  }
  return (
    <BookContainer>
      <BookHeader
        style={{ cursor: "pointer" }}
        onClick={() => setOpen((o) => !o)}
      >
        {!open && (
          <MiniCover>
            <img src={book.image} alt="book cover" />
          </MiniCover>
        )}
        <TitleContainer>
          <TitleSuperscript>{open ? "Title" : book.author}</TitleSuperscript>
          <Title>{book.title}</Title>
        </TitleContainer>
        <ToggleOpen onClick={() => setOpen((o) => !o)}>
          <FontAwesomeIcon icon={open ? faAngleUp : faAngleDown} size={"lg"} />
        </ToggleOpen>
      </BookHeader>
      {open && (
        <>
          <BookBody>
            <BookCoverHolder>
              <img src={book.image} alt="book cover" />
            </BookCoverHolder>
            <BookDetails>
              <DetailsRow>
                <BookDetail title={"Author"} body={book.author} />
                <BookDetail title={"Link"} body={book.link} />
              </DetailsRow>
              <DetailsRow>
                <BookDetail title={"Summary"} body={book.summary} />
              </DetailsRow>
              <DetailsRow>
                <BookDetail
                  title={"Highlights"}
                  body={book.highlights ?? "N/A"}
                />
              </DetailsRow>
              <DetailsRow>
                <BookDetail title={"Category"} body={ bookCategory(book.category)} />
              </DetailsRow>
              <DetailsRow>
                <BookDetail
                  title={"Video Link"}
                  body={book.videoLink ?? "N/A"}
                />
              </DetailsRow>
            </BookDetails>
          </BookBody>
          {
            <BookButtonRow>
              <a
                style={{ color: "black", textDecoration: "none" }}
                href={
                  "https://romantic-archimedes-0bd35e.netlify.app/growth-notes/" +
                  book.slug
                }
                target="_blank"
                rel="noreferrer"
              >
                <Button secondary>
                  <span>View</span>
                </Button>
              </a>
              <Button
                primary
                onClick={() =>
                  openModal(
                    <BookModal book={book} refreshBookList={refreshBookList} />,
                    "Edit Book",
                    "books"
                  )
                }
              >
                <span>Edit</span>
              </Button>
            </BookButtonRow>
          }
        </>
      )}
    </BookContainer>
  );
};

const ToggleOpen = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border-radius: 100%;
  cursor: pointer;
  width: 20px;
  height: 20px;

  &:hover {
    background-color: #eee;
  }
`;

const BookButtonRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
`;

const BookContainer = styled(ElementContainer)`
  margin-bottom: 1rem;
`;

const BookHeader = styled.div`
  display: flex;
  // justify-content: space-between;
  align-items: center;
`;

const BookBody = styled.div`
  display: grid;
  grid-template-columns: 25% 75%;
  margin-top: 2rem;
`;

const BookCoverHolder = styled.div`
  width: 100%;
  > img {
    width: 72%;
  }
`;

const BookDetails = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const DetailsRow = styled.div`
  width: 100%;
  display: flex;
`;

const BookDetail = ({ title, body }) => {
  return (
    <Detail>
      <DetailTitle>{title}</DetailTitle>
      {title === "Link" ? (
        <DetailLink href={body}>{body}</DetailLink>
      ) : (
        <DetailBody>{body}</DetailBody>
      )}
    </Detail>
  );
};

const Detail = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  margin-bottom: 2rem;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 5;
  margin-left: 2rem;
`;

const DetailTitle = styled.span`
  font-size: var(--font-size-7);
  font-weight: 700;
`;

const DetailBody = styled.p`
  margin: 0;
  font-size: var(--font-size-5);
  overflow-wrap: anywhere;
`;

const DetailLink = styled.a`
  margin: 0;
  font-size: var(--font-size-5);
  overflow-wrap:anywhere;
`;

const Title = styled.span`
  font-size: var(--font-size-2);
  font-weight: 700;
`;

const TitleSuperscript = styled.span`
  font-size: var(--font-size-5);
`;

const MiniCover = styled.div`
  // flex-grow: 1;
  // width: 80px;
  height: 70px;
  > img {
    width: 50px;
  }
`;
