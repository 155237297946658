import { useState, useEffect, createContext, useContext, useMemo } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfoCircle,
  faExclamationTriangle,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";

const NotificationsExternalContext = createContext();
const NotificationsInternalContext = createContext();
const ExternalProvider = NotificationsExternalContext.Provider;
const InternalProvider = NotificationsInternalContext.Provider;

const NOTIFICATION_LIFETIME = 3000;

export const NotificationsProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);

  // Core Functions
  const addNotification = (type, message) => {
    setNotifications((n) => [
      ...n,
      {
        id: `${Math.random() * 1000}`,
        type,
        message,
        state: "active",
      },
    ]);
  };

  const removeNotification = (id) => {
    setNotifications((notifs) => notifs.filter((n) => n.id !== id));
  };

  // Helper Functions
  const notify = (notification) => {
    addNotification("notification", notification);
  };

  const warn = (warning) => {
    addNotification("warning", warning);
  };

  const error = (error, customMessage) => {
    if (customMessage) {
      addNotification("error", customMessage);
    } else {
      addNotification("error", "An error occurred");
    }
    console.error(error);
  };

  // Expose the functions
  const externalValue = useMemo(() => ({ notify, warn, error }), []);

  return (
    <ExternalProvider value={externalValue}>
      <InternalProvider value={{ notifications, removeNotification }}>
        {children}
      </InternalProvider>
    </ExternalProvider>
  );
};

export const useNotifications = () => {
  const store = useContext(NotificationsExternalContext);
  return store;
};

const Notifications = () => {
  const { notifications } = useContext(NotificationsInternalContext);
  return ReactDOM.createPortal(
    <NHolder>
      {notifications.map((n) => (
        <Notification
          key={n.id}
          id={n.id}
          type={n.type}
          message={n.message}
          state={n.state}
        />
      ))}
    </NHolder>,
    document.querySelector("#notifications-root")
  );
};

export default Notifications;

const Notification = ({ type, message, id }) => {
  const [fadeIn, setFadeIn] = useState(false);
  const { removeNotification } = useContext(NotificationsInternalContext);

  useEffect(() => {
    setFadeIn(true);
    const timer = setTimeout(() => {
      setFadeIn(false);
      //removeNotification(id);
    }, NOTIFICATION_LIFETIME);
    return () => clearTimeout(timer);
  }, []);

  return (
    <NotificationContainer
      type={type}
      key={id}
      onTransitionEnd={() => !fadeIn && removeNotification(id)}
      style={{ opacity: fadeIn ? 1 : 0 }}
    >
      <div>
        <FontAwesomeIcon
          icon={
            {
              notification: faInfoCircle,
              warning: faExclamationTriangle,
              error: faExclamationCircle,
            }[type]
          }
          color="var(--color-notification-contrast)"
        ></FontAwesomeIcon>
        <span>{message}</span>
      </div>
    </NotificationContainer>
  );
};

const NHolder = styled.div`
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  width: 320px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  z-index: var(--z-index-notifications);
`;

const NotificationContainer = styled.div`
  transition: var(--transition-time-notification) opacity;
  width: 100%;
  ${(p) =>
    p.type === "notification" && "background-color: var(--color-primary);"}
  ${(p) =>
    p.type === "warning" && "background-color: var(--color-warning);"}
    ${(
    p
  ) =>
    p.type === "error" &&
    "background-color: var(--color-error);"}
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--padding-notification-default);
  box-shadow: var(--box-shadow-3);
  margin: var(--margin-notification-default);

  border-radius: 2rem;

  > div {
    *:first-child {
      margin: var(--margin-button-icon-default);
    }
    > span {
      color: var(--color-notification-contrast);
    }
  }
`;
