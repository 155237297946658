import { useState, useContext, createContext, useMemo } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";

import { faTimes, faMoneyCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import book_icon from "../book_icon.png";

const ModalExternalContext = createContext();
const ModalInternalContext = createContext();
const ExternalProvider = ModalExternalContext.Provider;
const InternalProvider = ModalInternalContext.Provider;

export const ModalProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState(null);
  const [modalType, setModalType] = useState(null);
  const [modalContent, setModalContent] = useState(null);

  const openModal = (content, title, type) => {
    setModalContent(() => content);
    setModalTitle(() => title);
    setModalType(type);
    setIsOpen(() => true);
  };

  const closeModal = () => {
    setIsOpen(() => false);
    setModalContent(() => null);
    setModalTitle(() => null);
    setModalType(() => null);
  };

  const externalValue = useMemo(
    () => ({
      openModal,
      closeModal,
    }),
    []
  );

  const internalValue = {
    isOpen,
    modalTitle,
    modalType,
    modalContent,
  };

  return (
    <ExternalProvider value={externalValue}>
      <InternalProvider value={internalValue}>{children}</InternalProvider>
    </ExternalProvider>
  );
};

export const useModal = () => {
  const store = useContext(ModalExternalContext);
  return store;
};

const Modal = () => {
  const { isOpen, modalContent, modalTitle, modalType } = useContext(
    ModalInternalContext
  );

  const { closeModal } = useContext(ModalExternalContext);

  const overlayClick = (e) => {
    e.stopPropagation();
    closeModal();
  };
  const containerClick = (e) => {
    e.stopPropagation();
  };

  return ReactDOM.createPortal(
    isOpen && (
      <ModalOverlay onClick={overlayClick}>
        <ModalContainer onClick={containerClick}>
          <ModalHeaderElement title={modalTitle} type={modalType} />
          {/*
            <CloseButton onClick={closeModal}>
              <FontAwesomeIcon
                icon={faTimes}
                color={"#aaa"}
                style={{ cursor: "pointer" }}
              />
            </CloseButton>
          */}
          {modalContent}
        </ModalContainer>
      </ModalOverlay>
    ),
    document.querySelector("#modal-root")
  );
};

export default Modal;

const ModalHeaderElement = ({ title, type }) => {
  return (
    <HeaderContainer type={type}>
      {
        {
          books: (
            <div style={{ width: "16px", display: "grid" }}>
              <img src={book_icon} alt="book icon" style={{ width: "100%" }} />
            </div>
          ),
          pricing: <FontAwesomeIcon icon={faMoneyCheck} />,
        }[type]
      }
      <span>{title}</span>
    </HeaderContainer>
  );
};

const HeaderContainer = styled.div`
  background-color: ${(p) =>
    p.type === "books"
      ? "var(--color-books-background)"
      : "var(--color-pricing-background)"};
  box-shadow: var(--box-shadow-1);
  position: relative;
  display: flex;
  align-items: center;
  padding: 1rem;
  left: -40px;
  top: -40px;
  border-radius: 2rem;
  width: fit-content;
  *:first-child {
    margin-right: 1rem;
  }
  > span {
    font-weight: 700;
  }
`;

const CloseButton = styled.i`
  cursor: pointer;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  display: grid;
  place-items: center;
  position: relative;
  right: 0px;
  top: 0px;

  &:hover {
    background-color: var(--color-hover-opacity);
  }
`;

const ModalContainer = styled.div`
  border-radius: 2rem;
  max-width: 80vw;
  padding: 2rem;
  min-width: 150px;
  cursor: default;
  background-color: var(--color-paper);
  display: flex;
  flex-direction: column;
  background-color: white;

  box-shadow: var(--box-shadow-3);
`;

const ModalOverlay = styled.div`
  position: fixed;
  cursor: pointer;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: grid;
  place-items: center;
  z-index: var(--z-index-modal);
`;
