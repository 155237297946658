import { useEffect, useState } from "react";
import styled from "styled-components";

import Input from "components/Input";
import TextArea from "components/TextArea";
import Button from "components/Button";

import Loading from "components/Loading";
import { useNotifications } from "context/Notifications";
import { getPageContent, addPageContent, updatePageContent } from "../firebase/firebase.utils";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const PageCycle = ({ page, pageTitle, showFirstField, cTitle }) => {
  const [loading, setLoading] = useState(true);
  const [waiting, setWaiting] = useState(null);

  const [title, setTitle] = useState("");
  const [subtext, setSubText] = useState("");
  const N = useNotifications();
  const [id, setId] = useState(null);

  useEffect(() => {
    (async () => {
      const response = await getPageContent(page);
            
      if(response) {
        setId(response.id ? response.id : null);
        var content = JSON.parse(response.content);

        setTitle(content['title'] ? content['title'] : null);
        setSubText(content['subtext'] ? content['subtext'] : null);
      }
      setLoading(false);
    })();
  }, []);
  const handleInputChange = (e) => {
    switch (e.target.name) {
      case "title":
        setTitle(e.target.value);
        break;
      case "subtext":
        setSubText(e.target.value);
        break;
      default:
        console.error("Field name not found");
    }
  };
  const handleSubmit = async () => {
    // validation
    if ( !subtext) {
      N.warn("Missing required fields");
      return;
    }
    setWaiting("submit");

    try {
      console.log("done");
      // firestore save (using url from upload)
      if (!id) {
        let item = {
          title,
          subtext
        };
        let data = {
          page,
          content : JSON.stringify(item),
        }
        await addPageContent(data);
        setWaiting(null);
      } else {
        let item = {
          title,
          subtext
        };
        let data = {
          page,
          content : JSON.stringify(item),
        }
        await updatePageContent(id, data);
        setWaiting(null);
      }
    } catch (err) {
      setWaiting(null);
      N.error(err);
    }
  };

  return (
    <Container>
      {loading && <Loading />}
      {!loading &&(
        <BookDetails>
          <Title>
            { pageTitle }
          </Title>
          <DetailsRow>
            {showFirstField && (
              <BookDetailForm
                title={"Title"}
                value={title}
                valueName={"title"}
                handleInputChange={handleInputChange}
              />
            )}
            <Detail>
              <DetailTitle>{cTitle}</DetailTitle>
              <ReactQuill theme="snow" value={ subtext } onChange={ setSubText }/>
            </Detail>
          </DetailsRow>
          <BookButtonRow>
            <Button primary loading={waiting === "submit"} onClick={handleSubmit}>
              <span>Save</span>
            </Button>
          </BookButtonRow>
        </BookDetails>
      )}
    </Container>
  );
};

export default PageCycle;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const BookDetailForm = ({
  title,
  value,
  valueName,
  handleInputChange,
  type,
}) => {
  return (
    <Detail>
      <DetailTitle>{title}</DetailTitle>
      {type === "textarea" && (
        <TextArea onChange={handleInputChange} name={valueName} value={value} />
      )}
      {type === "checkbox" && (
        <div style={{ maxWidth: "100%" }}>
          <input
            type={"checkbox"}
            onChange={handleInputChange}
            checked={value}
            name={valueName}
          />
        </div>
      )}
      {!type && (
        <Input onChange={handleInputChange} name={valueName} value={value} />
      )}
    </Detail>
  );
};

const BookDetails = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const DetailsRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Detail = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  margin-bottom: 2rem;
`;

const DetailTitle = styled.span`
  font-size: var(--font-size-7);
  font-weight: 700;
`;


const Title = styled.span`
  font-size: var(--font-size-2);
  font-weight: 700;
  margin-bottom: 2rem;
`;

const BookButtonRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
`;