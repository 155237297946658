import { default as ReactSelect } from "react-select";

// Custom Styles - see list here: https://react-select.com/styles

// Pass custom styles into the styles prop of the <ReactSelect>
/*
const customStyles  = {
    option: (provided, state) 
}
*/

const Select = ({
  options,
  setOptions,
  onChange,
  isClearable = true,
  isDisabled = false,
  isLoading = false,
  isMulti = false,
  isSearchable = true,
  value,
  ...rest
}) => {
  return (
    <ReactSelect
      isDisabled={isDisabled}
      isLoading={isLoading}
      isClearable={isClearable}
      isMulti={isMulti}
      isSearchable={isSearchable}
      options={options}
      onChange={onChange}
      value={value}
      {...rest}
    />
  );
};

export default Select;
