import { useCallback } from "react";
import styled from "styled-components";
import { useDropzone } from "react-dropzone";

const Dropzone = ({ image, newImageName, handleFileUpload }) => {
  const onDrop = useCallback((acceptedFiles) => {
    handleFileUpload(acceptedFiles);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <Container {...getRootProps()}>
      <input {...getInputProps()} />
      {newImageName ? (
        <span>{newImageName}</span>
      ) : (
        <>
          {image ? (
            <>
              <img src={image} alt={"book cover"} />
              <span style={{ textDecoration: "underline" }}>
                Click to replace cover
              </span>
            </>
          ) : (
            <>
              {isDragActive ? (
                <span>Drop the cover here ...</span>
              ) : (
                <span>Drag/Drop book cover here, or click to select file</span>
              )}
            </>
          )}
        </>
      )}
    </Container>
  );
};

export default Dropzone;

const Container = styled.div`
  cursor: pointer;
  width: 100%;
  height: 100%;
  background-color: #eee;
  border-radius: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  > span {
    text-align: center;
    color: var(--color-primary);
  }
  > img {
    width: 100%;
  }
  border: 5px dashed var(--color-primary);
`;
