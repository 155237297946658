import styled from "styled-components";
import logo from "../growth-notes-logo3x.png";
import book_icon from "../book_icon.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPowerOff,
  faList,
  faPlus,
  faShareAlt,
} from "@fortawesome/free-solid-svg-icons";

import BookModal from "components/BookModal";

import { useModal } from "context/Modal";

import { signOut } from "../firebase/firebase.utils";

import { Link } from "react-router-dom";

const Menu = ({ setKey, setView }) => {
  const { openModal } = useModal();

  const refreshBookList = () => {
    setKey((k) => k + 1);
  };
  return (
    <Container>
      <Link to="/">
        {/* <img src={logo} alt="logo"></img> */}
      </Link>
      <SectionContainer>
        <BooksHeader>
          <div>
            <img src={book_icon} alt="book icon" />
          </div>
          <span>Books</span>
        </BooksHeader>
        <BooksButton onClick={() => setView("books")}>
          <div>
            <FontAwesomeIcon icon={faList} />
          </div>
          <span>List</span>
        </BooksButton>
        <BooksButton onClick={() => setView("insights")}>
          <div>
            <FontAwesomeIcon icon={faList} />
          </div>
          <span>Insights</span>
        </BooksButton>
        {/* <BooksButton onClick={() => setView("library")}>
          <div>
            <FontAwesomeIcon icon={faList} />
          </div>
          <span>Library</span>
        </BooksButton> */}
        <BooksButton onClick={() => setView("member")}>
          <div>
            <FontAwesomeIcon icon={faList} />
          </div>
          <span>Member Guidelines</span>
        </BooksButton>
        <BooksButton onClick={() => setView("cycle")}>
          <div>
            <FontAwesomeIcon icon={faList} />
          </div>
          <span>Growth Cycle</span>
        </BooksButton>

        <BooksButton onClick={() => setView("howitwork")}>
          <div>
            <FontAwesomeIcon icon={faList} />
          </div>
          <span>How it Works</span>
        </BooksButton>

        <BooksButton onClick={() => setView("homecontent")}>
          <div>
            <FontAwesomeIcon icon={faShareAlt} />
          </div>
          <span>Home</span>
        </BooksButton>

        <BooksButton
          onClick={() =>
            openModal(
              <BookModal refreshBookList={refreshBookList} />,
              "New Book",
              "books"
            )
          }
        >
          <div>
            <FontAwesomeIcon icon={faPlus} />
          </div>
          <span>Add New Book</span>
        </BooksButton>
      </SectionContainer>
      <SectionContainer>
        <LogoutButton onClick={signOut}>
          <div>
            <FontAwesomeIcon
              icon={faPowerOff}
              color={"var(--color-logout-primary)"}
            />
          </div>
          <span>Logout</span>
        </LogoutButton>
      </SectionContainer>
    </Container>
  );
};

export default Menu;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  padding: 2rem 2rem 1rem 2rem;
  border-radius: 0 0 2rem 2rem;
  box-shadow: var(--box-shadow-2);
  background-color: white;
  width: 20vw;
  > * {
    margin-bottom: 1rem;
  }
  > a {
    width: 100%;
    > img {
      align-self: start;
      width: 100%;
    }
  }
`;

const SectionContainer = styled.div`
  border-radius: 2rem;
  width: 100%;
  flex-direction: column;
  display: flex;
  overflow: hidden;
`;

const MenuElement = styled.div`
  width: 100%;
  cursor: pointer;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  > div {
    margin-right: 1rem;
  }
  > span {
    font-weight: 700;
  }
`;

const BooksButton = styled(MenuElement)`
  background-color: var(--color-gray);
  &:hover {
    filter: brightness(0.9);
  }
`;

const PricingButton = styled(MenuElement)`
  background-color: var(--color-pricing-background);
  &:hover {
    filter: brightness(0.9);
    background-color: var(--color-pricing-background);
  }
`;

const LogoutButton = styled(MenuElement)`
  background-color: var(--color-logout-background);
  > span {
    color: var(--color-logout-primary);
  }
  &:hover {
    filter: brightness(0.9);
    background-color: var(--color-logout-background);
  }
`;

const BooksHeader = styled(MenuElement)`
  cursor: default;
  background-color: var(--color-books-background);
  > div {
    width: 16px;
    display: grid;
    > img {
      width: 100%;
    }
  }
`;
