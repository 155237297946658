import styled from "styled-components";

const ElementContainer = styled.div`
  width: 100%;
  padding: 2rem;
  border-radius: 2rem;
  background-color: white;
  box-shadow: var(--box-shadow-1);
`;

export default ElementContainer;
